import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import {
    HomePageRandomJoke,
    HomePageStarredEntities,
    HomePageCompanyLogo,
    HomePageTopVisited,
    HomePageRecentlyVisited,
    FeaturedDocsCard,
    HomePageToolkit,
    WelcomeTitle,
    TemplateBackstageLogo,
    TemplateBackstageLogoIcon,
} from '@backstage/plugin-home';

import { GitHubIcon, InfoCard } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { MicrosoftCalendarCard } from '@backstage/plugin-microsoft-calendar';
import { AzurePullRequestsIcon } from '@backstage/plugin-azure-devops';

const useStyles = makeStyles(theme => ({
    searchBarInput: {
        maxWidth: '60vw',
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '50px',
        boxShadow: theme.shadows[1],
    },
    searchBarOutline: {
        borderStyle: 'none'
    }
}));

const useLogoStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(5, 0),
    },
    svg: {
        width: 'auto',
        height: 100,
    },
    path: {
        fill: '#7df3e1',
    },
}));

export const HomePage = () => {
    const classes = useStyles();
    const { svg, path, container } = useLogoStyles();

    return (
        <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo className={container} logo={<TemplateBackstageLogo classes={{ svg, path }} />} />
            <Grid container item xs={12} sm={12} md={12} justifyContent='center'>
                <HomePageSearchBar InputProps={{ classes: { root: classes.searchBarInput, notchedOutline: classes.searchBarOutline }}} placeholder="Search" />
            </Grid>
            <Grid container item xs={12} sm={12} md={12} justifyContent='center'>
                <WelcomeTitle />
            </Grid>
            <Grid container item xs={12} sm={12} md={12} justifyContent='center'>
                <HomePageToolkit tools={[
                    {
                        url: 'https://dev.azure.com/hoffe/',
                        label: 'Azure DevOps',
                        icon: <AzurePullRequestsIcon />,
                    },
                    {
                        url: 'https://github.com/AIperion/',
                        label: 'GitHub',
                        icon: <GitHubIcon />,
                    },
                    {
                        url: 'http://sonar.aiperion.de:9000',
                        label: 'SonarQube',
                        icon: <TemplateBackstageLogoIcon />,
                    },
                ]} />
            </Grid>
            <Grid container item xs={12} sm={12} md={12} justifyContent="center">
                <Grid item xs={12} sm={5} md={5}>
                    <InfoCard title="Composable Section">
                        {/* placeholder for content */}
                        <div style={{ height: 370 }} />
                    </InfoCard>
                </Grid>
                <Grid item xs={12} sm={5} md={5}>
                    <InfoCard title="Composable Section">
                        {/* placeholder for content */}
                        <div style={{ height: 370 }} />
                    </InfoCard>
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={5} md={5}>
                <Grid item xs={12} sm={12} md={12}>
                    <MicrosoftCalendarCard />
                </Grid>
            </Grid>
            <Grid container item  xs={12} sm={5} md={5}>
                <Grid item xs={12} sm={6} md={6}>
                    <HomePageStarredEntities />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <HomePageRecentlyVisited />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <HomePageTopVisited />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                </Grid>
            </Grid>
            <Grid container item  xs={12} sm={12} md={12}  justifyContent="center">
                <Grid item xs={12} sm={5} md={5}>
                    <FeaturedDocsCard filter={{
                        'spec.type': 'documentation',
                        'metadata.name': 'getting-started-with-backstage',
                    }}  />
                </Grid>
                <Grid item xs={12} sm={5} md={5}>
                    <HomePageRandomJoke />
                </Grid>
            </Grid>
        </Grid>
    );
}